@tailwind base;
@tailwind components;
@tailwind utilities;

.root,
#root,
#docs-root {
  --primary: #26486B;
  --secondary: #0d7fa4;
  --secondaryDarker: #0b5b75;
  --accent: #718096;
  --background: #fff;
  --main: #0d0106;
  --header: #2d3748;
  --danger: #db4437;
  --dangerSecondary: #bf3d32;
}

.bold { font-weight: bold }

/*
* [CSS Contents]
*
* 0. Fonts
* 1. General
* 2. Breadcrumbs
* 3. Tables
* 4. Icons
*/


/* -----------------------
* 0. Fonts
------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Aleo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Delius&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../assets/fonts/OpenSans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../assets/fonts/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url("../assets/fonts/OpenSans/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url("../assets/fonts/Oswald/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url("../assets/fonts/Oswald/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url("../assets/fonts/Oswald/Oswald-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUI"),
    url("../assets/fonts/SegoeUI/SegoeUI-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SegoeUI";
  src: local("SegoeUI"),
    url("../assets/fonts/SegoeUI/SegoeUI-Bold.ttf") format("truetype");
  font-weight: bold;
}

video {
  object-fit: cover;
  object-position: center center;
}

@media only screen and (min-width: 768px) {
  iframe,
  video {
    border-radius: 15px;
  }
}

.slick-dots li {
  width: auto !important;
  height: auto !important;
}

.slick-dots .slick-active div {
  height: 8px !important;
  width: 8px !important;
  background-color: #FFF !important;
}

.SegoeUI {
  font-family: 'SegoeUI';
}

.Montserrat {
  font-family: 'Montserrat';
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #888888;
  color: #FFF;
}

.ttnk-button {
  @apply bg-gradient-to-br from-aeblue-dark to-aeblue text-white py-2 px-6 rounded-full disabled:bg-gray-200 flex justify-center items-center
}

.ttnk-button path {
  fill: #fff;
}

.ttnk-button:hover {
  @apply bg-gradient-to-br from-aeblue to-aeblue-dark
}

.ttnk-button:disabled {
  cursor: not-allowed;
  @apply bg-gradient-to-br from-gray-200 to-gray-100 text-gray-500
}

.ttnk-button.ttnk-button-outlined {
  border: solid 1px #ddd;
  background-image: none;
  background-color: #fff;
  color: #ddd;
}

.ttnk-button.ttnk-button-outlined path {
  fill: #ddd;
}

.ttnk-button.ttnk-button-outlined:hover {
  color: #000;
}

.ttnk-button.ttnk-button-outlined:hover path {
  fill: #000;
}

.ttnk-gradient-bg {
  background: radial-gradient(circle, rgba(19,40,80,1) 0%, rgba(0,0,0,1) 60%);
}

.inner-html p {
  margin: 14px 0;
}

.inner-html p.center > span > img, .inner-html p.center > img {
  display: block;
  margin: 0px auto;
}

.inner-html .center, .inner-html .center img {
  text-align: center;
  display: block;
  margin: 0px auto !important;
}

.inner-html .right, .inner-html .right img {
  text-align: right;
  display: block;
  margin-left: auto !important;
}

.inner-html li.right, .inner-html li.center {
  display: list-item !important;
  list-style-position: inside;
}

.inner-html .full {
  text-align: justify;
}

.inner-html img.center {
  display: block !important;
  margin: 0px auto;
}

.inner-html img.right {
  display: block !important;
}

.inner-html p > img {
  display: initial !important;
}

.inner-html h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.inner-html > table {
  border-style: none;
}

.inner-html > table + table {
  border-collapse: collapse;
  width: 100%;
}

.inner-html > table > colgroup {
  display: table-column-group;
}

.inner-html > table > tr {
  display: table-row;
}

.inner-html > table > td {
  padding: 0.4rem;
  display: table-cell;
  vertical-align: inherit;
}

.inner-html table[border]:not([style*=border-color]) td,
table[border]:not([border="0"]):not([style*=border-color]) th {
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
}

.inner-html table.center {
  display: table !important;
  margin: 0px auto;
}

.inner-html table.right {
  display: table !important;
  margin-left: auto !important;
}

.inner-html caption {
  display: table-caption;
  text-align: -webkit-center;
  border-collapse: collapse;
  caption-side: top;
}

.inner-html ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.inner-html li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.inner-html ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.inner-html a {
  text-decoration: underline;
}

.inner-html a:hover {
  text-decoration: none ;
}

.inner-html .underline {
  text-decoration: underline;
}

.inner-html .strikethrough {
  text-decoration: line-through;
}

.inner-html .underline.strikethrough {
  text-decoration: underline line-through !important;
}

.inner-html * {
  line-height: 1.4;
}

.inner-html {
  display: block;
  overflow-x: auto;
  max-width: 100%;
}

.line-clamp-2-name {
  display: inline-block;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  -webkit-line-clamp: 2 !important;
  text-overflow: ellipsis;
}

.inner-html.no-overflow {
  overflow-x: unset;
}

.inner-html [type=submit], .inner-html button, .inner-html html [type=button] {
  background-color: #EFEFEF;
  padding: 1px 6px;
  border: 1px solid black;
}

.ttci-break-word {
  word-break: break-word;
}

input::placeholder, textarea::placeholder {
  color: #888888;
}

.ReactModal__Overlay {
  background-color: #00000080 !important;
}

.scrollable-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
 background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
 background: #888;
 border-radius: 5px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
 background: #555;
}

.timezone-dropdown {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position-x: calc(100% - 14px);
}

.actionform .ant-upload-select-picture-card {
  height: auto!important;
}